import React from "react";

const HomePage = () => {
  return (
    <div className="App font-face-quiny">
      <div className="title-container">
        <div className="title">
          <span>- al</span>
          <span>varokoke -</span>
        </div>
      </div>
      <div className="socials_1 font-face-mulingar">under construction</div>
    </div>
  );
};

export default HomePage;
